import React, { useCallback } from 'react';
import { TableRow as MuiTableRow, TableCell, IconButton } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight, Add, Remove } from '@mui/icons-material';

const TableRow = React.memo(({ 
  item, 
  depth = 0, 
  expandedCategories, 
  setExpandedCategories,
  selectedMetrics,
  setSelectedMetrics,
  getChildren,
}) => {
  const children = getChildren(item.id);
  const hasChildren = children.length > 0;
  const hasMapping = item.item_mapping && item.item_mapping.length > 0;
  const isExpanded = expandedCategories.has(item.id);

  const isMetricSelected = useCallback(() => {
    const isSelected = selectedMetrics?.some(m => m.id === item.id);
    return isSelected;
  }, [selectedMetrics, item.id]);

  const handleAddMetric = useCallback((e) => {
    e.stopPropagation();
    
    setSelectedMetrics(prev => {
      const prevMetrics = Array.isArray(prev) ? prev : [];
      const exists = prevMetrics.some(m => m.id === item.id);
      const newMetrics = exists 
        ? prevMetrics.filter(m => m.id !== item.id)
        : [...prevMetrics, {
            id: item.id,
            label: item.label,
            item_mapping: item.item_mapping
          }];
      return newMetrics;
    });
  }, [item, setSelectedMetrics]);

  const handleRowClick = useCallback((e) => {
    e.stopPropagation();
    if (hasChildren) {
      setExpandedCategories(prev => {
        const newSet = new Set(prev);
        if (newSet.has(item.id)) {
          newSet.delete(item.id);
        } else {
          newSet.add(item.id);
        }
        return newSet;
      });
    }
  }, [hasChildren, item.id, setExpandedCategories]);

  return (
    <React.Fragment>
      <MuiTableRow
        onClick={handleRowClick}
        sx={{ 
          cursor: hasChildren ? 'pointer' : 'default',
          backgroundColor: hasChildren ? '#e3f2fd' : '#fff',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
          height: '28px',
        }}
      >
        <TableCell
          sx={{
            pl: `${(depth + (hasChildren ? 1 : 2)) * 16}px`,
            py: 0.25,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            color: '#000000',
          }}
        >
          <div style={{ 
            display: 'flex', 
            alignItems: 'center',
            color: '#1a1a1a',
          }}>
            {hasChildren && (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowClick(e);
                }}
                sx={{ 
                  mr: 1,
                  color: '#000000',
                  '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                  },
                }}
              >
                {isExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            )}
            <span style={{ fontSize: '0.875rem' }}>{item.label}</span>
          </div>
          {hasMapping && (
            <IconButton
              onClick={handleAddMetric}
              size="small"
              sx={{
                color: isMetricSelected() ? '#d32f2f' : '#2e7d32',
                padding: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  color: isMetricSelected() ? '#b71c1c' : '#1b5e20',
                },
              }}
            >
              {isMetricSelected() ? <Remove /> : <Add />}
            </IconButton>
          )}
        </TableCell>
      </MuiTableRow>
      {isExpanded && children.map(child => (
        <TableRow 
          key={child.id} 
          item={child} 
          depth={depth + 1}
          expandedCategories={expandedCategories}
          setExpandedCategories={setExpandedCategories}
          selectedMetrics={selectedMetrics}
          setSelectedMetrics={setSelectedMetrics}
          getChildren={getChildren}
        />
      ))}
    </React.Fragment>
  );
});

export default TableRow;