import React from 'react';
import { Stack, Typography, Grid, Box } from '@mui/material';
import PropTypes from 'prop-types';

const MetricDisplay = ({ label, values }) => {
  console.log('MetricDisplay values:', { label, values });
  
  const metricLabels = {
    'E': 'Estimate',
    'M': 'Margin of Error',
    'PE': '% Estimate',
    'PM': '% Margin of Error'
  };

  const formatValue = (value, type, label) => {
    // Convert to number if it's a string that can be parsed
    const num = typeof value === 'string' ? parseFloat(value) : value;
    
    // If not a valid number, return original value
    if (isNaN(num)) return value;
    
    // Add dollar sign if label contains "dollars" and it's an estimate or margin
    const needsDollar = label.toLowerCase().includes('dollars') && (type === 'E' || type === 'M');
    
    // Format number with commas
    const formattedNum = num.toLocaleString();
    
    return `${needsDollar ? '$' : ''}${formattedNum}`;
  };

  return (
    <Stack 
      spacing={0.5} 
      sx={{
        backgroundColor: '#fff',
        my: 1,
        borderRadius: 1,
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        maxWidth: '240px',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          backgroundColor: '#2196f3',
          display: 'flex',
          alignItems: 'center',
          px: 1,
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Typography 
          variant="body2"
          sx={{ 
            color: '#fff',
            fontWeight: 400,
            fontSize: '0.875rem',
            lineHeight: 1,
          }}
        >
          {label}
        </Typography>
      </Box>

      <Grid container spacing={0.5} sx={{ p: 1, mt: '0 !important' }}>
        {Object.entries(metricLabels)
          .filter(([type]) => !(['PE', 'PM'].includes(type) && values['PM'] < 0))
          .map(([type, metricLabel]) => (
            <Grid item xs={12} key={type}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  p: 0.5,
                  backgroundColor: type.includes('E') ? '#e3f2fd' : '#fff',
                  borderRadius: 0.5,
                  border: '1px solid',
                  borderColor: 'grey.200',
                  minHeight: '24px',
                  width: '100%'
                }}
              >
                <Typography 
                  variant="caption"
                  sx={{ 
                    color: 'grey.700',
                    fontWeight: 500
                  }}
                >
                  {metricLabel}:
                </Typography>
                <Typography 
                  variant="caption"
                  sx={{ 
                    fontWeight: 600,
                    ml: 0.5
                  }}
                >
                  {formatValue(values[type], type, label)}
                  {(type === 'PE' || type === 'PM') && '%'}
                </Typography>
              </Box>
            </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

MetricDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({
    E: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    M: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    PE: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    PM: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired
};

export default MetricDisplay;