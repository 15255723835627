import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import TableRow from './TableRow';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const MetricSelection = ({
  metricsModified,
  handleReload,
  showMetricsSelection,
  setShowMetricsSelection,
  MetricHierarchy,
  expandedCategories,
  setExpandedCategories,
  handleMetricChange,
  getChildren,
  selectedMetrics,
  primaryMetric,
  handleSetPrimaryMetric
}) => {
  // Add new state for Selected Metrics dropdown
  const [showSelectedMetrics, setShowSelectedMetrics] = React.useState(true);

  return (
    <div style={{ 
      marginTop: '20px',
      padding: '15px',
      backgroundColor: '#f5f5f5',
      borderRadius: '5px',
      border: '1px solid #ddd'
    }}>
      {/* Add Metrics Selection Header */}
      <div 
        onClick={() => setShowMetricsSelection(!showMetricsSelection)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          padding: '8px',
          backgroundColor: 'white',
          borderRadius: '4px',
          border: '1px solid #ddd'
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TuneIcon sx={{ marginRight: '8px', color: '#666' }} />
          <h3 style={{ margin: 0, fontSize: '18px' }}>Available Metrics</h3>
        </div>
        <KeyboardArrowDownIcon 
          sx={{ 
            color: '#666',
            transform: showMetricsSelection ? 'rotate(180deg)' : 'rotate(0)',
            transition: 'transform 0.3s ease-in-out'
          }} 
        />
      </div>

      {/* Metrics Selection Content */}
      <div style={{
        maxHeight: showMetricsSelection ? '400px' : '0',
        overflowY: showMetricsSelection ? 'auto' : 'hidden',
        overflowX: 'hidden',
        transition: 'all 0.3s ease-in-out',
        marginBottom: showMetricsSelection ? '20px' : '0',
        opacity: showMetricsSelection ? 1 : 0,
        transform: showMetricsSelection ? 'translateY(0)' : 'translateY(-10px)',
      }}>
        {MetricHierarchy && (
          <div style={{ 
            backgroundColor: 'white',
            border: '1px solid #ddd',
            borderRadius: '5px',
            marginTop: '10px',
            transition: 'all 0.3s ease-in-out',
          }}>
            <table style={{ 
              width: '100%',
              borderCollapse: 'collapse'
            }}>
              <tbody>
                {Object.values(MetricHierarchy)
                  .filter(item => !item.parent)
                  .map(item => (
                    <TableRow 
                      key={item.id} 
                      item={item}
                      expandedCategories={expandedCategories}
                      setExpandedCategories={setExpandedCategories}
                      selectedMetrics={selectedMetrics}
                      setSelectedMetrics={handleMetricChange}
                      getChildren={getChildren}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Warning Banner - moved to bottom */}
      {metricsModified && (
        <div style={{
          backgroundColor: '#fff3e0',
          color: '#e65100',
          padding: '8px 12px',
          borderRadius: '4px',
          marginTop: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '0.9rem'
        }}>
          <span>Metrics have been modified. Click to update map data.</span>
          <button
            onClick={handleReload}
            style={{
              backgroundColor: '#ff9800',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              padding: '4px 8px',
              cursor: 'pointer',
              fontSize: '0.8rem'
            }}
          >
            Update
          </button>
        </div>
      )}

      {/* Selected Metrics Section */}
      {/* <div style={{ 
        marginTop: '20px',
        paddingTop: '20px'
      }}> */}
        {/* Selected Metrics Header */}
        <div 
          onClick={() => setShowSelectedMetrics(!showSelectedMetrics)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            padding: '8px',
            backgroundColor: 'white',
            borderRadius: '4px',
            border: '1px solid #ddd'
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TuneIcon sx={{ marginRight: '8px', color: '#666' }} />
            <h3 style={{ margin: 0, fontSize: '18px' }}>Selected Metrics</h3>
          </div>
          <KeyboardArrowDownIcon 
            sx={{ 
              color: '#666',
              transform: showSelectedMetrics ? 'rotate(180deg)' : 'rotate(0)',
              transition: 'transform 0.3s ease-in-out'
            }} 
          />
        </div>
        
        {/* Selected Metrics Content */}
        <div style={{
          maxHeight: showSelectedMetrics ? '400px' : '0',
          overflowY: showSelectedMetrics ? 'auto' : 'hidden',
          overflowX: 'hidden',
          transition: 'all 0.3s ease-in-out',
          marginBottom: showSelectedMetrics ? '20px' : '0',
          opacity: showSelectedMetrics ? 1 : 0,
          transform: showSelectedMetrics ? 'translateY(0)' : 'translateY(-10px)',
        }}>
          {selectedMetrics.length === 0 ? (
            <p style={{ padding: '10px' }}>No metrics selected</p>
          ) : (
            <div style={{ padding: '10px 0' }}>
              {selectedMetrics.map(metric => (
                <Grid container spacing={0} key={metric.id} style={{ marginBottom: '8px' }}>
                  <Grid item xs={9}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      borderRadius: '4px 0 0 4px',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderRight: 'none'
                    }}>
                      <span style={{ fontSize: '16px' }}>{metric.label}</span>
                    </div>
                  </Grid>
                  <Grid item xs={1.5}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderLeft: 'none',
                      borderRight: 'none'
                    }}>
                      <Tooltip title={primaryMetric?.id === metric.id ? 'Primary Metric' : 'Set as Primary Metric'} arrow placement="top">
                        {primaryMetric?.id === metric.id ? (
                          <RadioButtonCheckedIcon 
                            fontSize="medium"
                            onClick={() => handleSetPrimaryMetric(metric)}
                            sx={{
                              cursor: 'pointer',
                              color: '#2196f3',
                              '&:hover': {
                                backgroundColor: 'rgba(33, 150, 243, 0.1)'
                              }
                            }}
                          />
                        ) : (
                          <RadioButtonCheckedIcon 
                            fontSize="medium"
                            onClick={() => handleSetPrimaryMetric(metric)}
                            sx={{
                              cursor: 'pointer',
                              color: '#4CAF50',
                              '&:hover': {
                                backgroundColor: 'rgba(76, 175, 80, 0.1)'
                              }
                            }}
                          />
                        )}
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={1.5}>
                    <div style={{
                      padding: '8px',
                      backgroundColor: 'white',
                      borderRadius: '0 4px 4px 0',
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: primaryMetric?.id === metric.id ? '1px solid #2196f3' : '1px solid #ddd',
                      borderLeft: 'none'
                    }}>
                      <Tooltip title="Remove Metric" arrow placement="top">
                        <CloseIcon 
                          fontSize="medium"
                          onClick={() => handleMetricChange(prev => 
                            prev.filter(m => m.id !== metric.id)
                          )}
                          sx={{
                            cursor: 'pointer',
                            color: '#f44336',
                            '&:hover': {
                              backgroundColor: 'rgba(244, 67, 54, 0.1)'
                            }
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              ))}
            </div>
          )}
        </div>
      </div>
    // </div>
  );
};

MetricSelection.propTypes = {
  metricsModified: PropTypes.bool.isRequired,
  handleReload: PropTypes.func.isRequired,
  showMetricsSelection: PropTypes.bool.isRequired,
  setShowMetricsSelection: PropTypes.func.isRequired,
  MetricHierarchy: PropTypes.object,
  expandedCategories: PropTypes.instanceOf(Set).isRequired,
  setExpandedCategories: PropTypes.func.isRequired,
  handleMetricChange: PropTypes.func.isRequired,
  getChildren: PropTypes.func.isRequired,
  selectedMetrics: PropTypes.array.isRequired,
  primaryMetric: PropTypes.object,
  handleSetPrimaryMetric: PropTypes.func.isRequired
};

export default MetricSelection; 