import React, { useState, useEffect, useRef, useMemo, createContext, useContext } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap, ZoomControl, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import axios from "axios";
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import mapColors from './map_colors.json';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Link } from 'react-router-dom';
import MetricDisplay from './MetricDisplay';
import { Box } from '@mui/material';
import ColorControls from './ColorControls';
import MetricSelection from './MetricSelection';
// Create a context for selected metrics
export const MetricsContext = createContext();


const StateMap = ({ initialState, initialMetrics, hierarchyType }) => {
  const [selectedState, setSelectedState] = useState(initialState || '');
  const [selectedMetrics, setSelectedMetrics] = useState(initialMetrics || []);
  const [MetricHierarchy, setMetricHierarchy] = useState(null);
  const [expandedCategories, setExpandedCategories] = useState(new Set());
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [mapDefinitions, setMapDefinitions] = useState(null);
  const [error, setError] = useState(null);
  const [maxBounds, setMaxBounds] = useState(null);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const mapRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(null);
  const [tractGeoJson, setTractGeoJson] = useState(null);
  const [selectedTractId, setSelectedTractId] = useState(null);
  const [isLoadingTracts, setIsLoadingTracts] = useState(false);
  const [viewMode, setViewMode] = useState('counties');
  const [countyGeoJson, setCountyGeoJson] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [showMetricsSelection, setShowMetricsSelection] = useState(false);
  const [metricsModified, setMetricsModified] = useState(false);
  const [primaryMetric, setPrimaryMetric] = useState(null);
  const [opacitySelected, setOpacitySelected] = useState(0.6);
  const [opacityUnselected, setOpacityUnselected] = useState(0.3);
  const lineColor = ['#FF0000', '#000000']
  const [selectedColorGradient, setSelectedColorGradient] = useState(Object.keys(mapColors.list_of_9)[0] || 'red_to_green');
  const [opacityColorGradient, setOpacityColorGradient] = useState(0.3);
  const [isColorControlsCollapsed, setIsColorControlsCollapsed] = useState(false);
  const [selectedHierarchyType, setSelectedHierarchyType] = useState(hierarchyType || 'DP02');
  const [metricsData, setMetricsData] = useState({
    headers: [],
    data: {}
  });


  const mergeMetricsData = (newData) => {
    console.log('New metrics data received:', newData);
    setMetricsData(prevData => {
      // If no previous data, initialize with the new data
      if (!prevData.headers.length) {
        return {
          headers: newData.headers,
          data: Object.fromEntries(
            Object.entries(newData)
              .filter(([key]) => !['headers', 'metric'].includes(key))
              .map(([countyId, tractData]) => [
                countyId,
                tractData
              ])
          )
        };
      }

      // Merge headers
      const existingHeadersSet = new Set(prevData.headers);
      const newHeaders = [...prevData.headers];
      const headerMapping = {};
      
      newData.headers.forEach((header, index) => {
        if (!existingHeadersSet.has(header)) {
          headerMapping[header] = newHeaders.length;
          newHeaders.push(header);
        } else {
          headerMapping[header] = prevData.headers.indexOf(header);
        }
      });

      // Merge data
      const mergedData = { ...prevData.data };
      
      // Process only county and tract data (skip headers and metric)
      Object.entries(newData)
        .filter(([key]) => !['headers', 'metric'].includes(key))
        .forEach(([countyId, tractData]) => {
          if (!mergedData[countyId]) {
            mergedData[countyId] = {};
          }
          
          Object.entries(tractData).forEach(([tractId, values]) => {
            if (!mergedData[countyId][tractId]) {
              // Initialize array with nulls for all possible metrics
              mergedData[countyId][tractId] = new Array(newHeaders.length).fill(null);
            }
            
            // Ensure values is an array and map the values to the correct positions
            if (Array.isArray(values)) {
              values.forEach((value, index) => {
                const targetIndex = headerMapping[newData.headers[index]];
                if (targetIndex !== undefined) {
                  mergedData[countyId][tractId][targetIndex] = value;
                }
              });
            }
          });
        });

      return {
        headers: newHeaders,
        data: mergedData
      };
    });
  };

  useEffect(() => {
    const fetchMapDefinitions = async () => {
      try {
        const definitionsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/utils/state_map_definitions`);
        setMapDefinitions(definitionsResponse.data);

        if (selectedState) {  // Only fetch if we have a selectedState
          const geoJsonResponse = await axios.get(`${process.env.REACT_APP_API_URL}/geometries/counties/${selectedState}`);
          setCountyGeoJson(geoJsonResponse.data);
          setGeoJsonData(geoJsonResponse.data);
        }
      } catch (err) {
        setError(err.message);
      }
    };

    fetchMapDefinitions();
  }, [selectedState]);

  useEffect(() => {
    const fetchHierarchyData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/utils/metric-hierarchies/${selectedHierarchyType}`
        );
        setMetricHierarchy(response.data.categories);
      } catch (error) {
        console.error('Error fetching hierarchy data:', error);
      }
    };

    fetchHierarchyData();
  }, [selectedHierarchyType]);

  useEffect(() => {
    if (mapDefinitions && selectedState) {
      
      const state = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      const newMaxBounds = L.latLngBounds(
        [state.max_south-10, state.max_west-10],
        [state.max_north+10, state.max_east+10]
      );
      setMaxBounds(newMaxBounds);
    } else {
      // Default bounds for the entire US
      const defaultBounds = L.latLngBounds(
        [18.905547-2, -195.000000+2], // Southwest coordinates
        [71.341324+2, -66.934570+2]   // Northeast coordinates
      );
      setMaxBounds(defaultBounds);
    }
  }, [mapDefinitions, selectedState]);

  // Use useMemo to compute initialCenter
  const initialCenter = useMemo(() => {
    if (selectedState && mapDefinitions ) {
      const def = mapDefinitions[selectedState.toLowerCase().replace(/ /g, '_')];
      return [
        def.centroid_lat,
        def.centroid_long
      ];
    }
    return [39.8283, -98.5795]; // Default center of US
  }, [mapDefinitions, selectedState]);

  const initialZoom = 7;
  const minZoom =  2.5;
  const maxZoom =  20;

  // Add this useEffect hook
  useEffect(() => {
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 100); // Delay to allow for CSS transition
    }
  }, [isPanelVisible]);

  const togglePanelVisibility = () => {
    setIsPanelVisible(!isPanelVisible);
  };

  const style = (feature) => {
    const isSelected = selectedFeatureId === feature.properties.GEOID;
    
    return {
      fillColor: isSelected ? '#2196f3' : '#ffffff',
      weight: isSelected ? 3 : 2,
      opacity: isSelected ? opacitySelected : opacityUnselected, // Change opacity based on selection
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  };

  const onEachFeature = (feature, layer) => {
    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        setSelectedFeatureId(feature.properties.GEOID);
        setSelectedFeature(feature);
        console.log(feature.properties)
        // Extract metrics data for the selected county
        const countyId = feature.properties.GEOID.slice(-3);
        console.log(countyId, typeof countyId)
        const countyMetrics = metricsData.data[countyId];
        
        // Log the metrics data for this county
        console.log('County ID:', countyId);
        console.log('County Metrics:', countyMetrics);
        console.log('Metrics Headers:', metricsData.headers);
        
        layer.setStyle(style(feature));
      },
    });
  };

  // Helper function to get popup position
  const getPopupPosition = (feature) => {
    // Try to get coordinates from different possible properties
    if (feature.properties.centroid_lat && feature.properties.centroid_long) {
      return [feature.properties.centroid_lat, feature.properties.centroid_long];
    }
    
    // Fallback: try to calculate center of the feature
    if (feature.geometry) {
      try {
        const bounds = L.geoJSON(feature).getBounds();
        return bounds.getCenter();
      } catch (error) {
        console.error('Error calculating feature center:', error);
      }
    }
    
    // Final fallback: use map center
    return initialCenter;
  };

  const handleViewTracts = async (geoid) => {
    try {
      setIsLoadingTracts(true);
      
      // Get geometry data
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/geometries/tracts_geometry/${selectedState}/${geoid}`
      );

      // Fetch geometry data
      const geometryResponse = await axios.get(response.data.geometry);
      const tractData = geometryResponse.data;
      
      // Update map with geometry only first
      setTractGeoJson(tractData);
      setGeoJsonData(null);
      setSelectedFeature(null);
      setViewMode('tracts');
      
      // Update map view if bounds are available
      if (tractData.bounds && 
          tractData.bounds.centroid_lat && 
          tractData.bounds.centroid_long) {
        mapRef.current.flyTo(
          [tractData.bounds.centroid_lat, tractData.bounds.centroid_long],
          10,
          {
            duration: 2.5,
            easeLinearity: 0.25
          }
        );
      }

      // Get the county ID from the GEOID
      const countyId = geoid.slice(-3);
      const countyMetricsData = metricsData.data[countyId];

      console.log('County ID:', countyId);
      console.log('County Metrics Data:', countyMetricsData);
      console.log('Metrics Headers:', metricsData.headers);

      // Update the tract GeoJSON with the metrics data
      const updatedTractData = {
        ...tractData,
        features: tractData.features.map(feature => {
          const tractId = feature.properties.TRACTCE;
          const metricValues = countyMetricsData?.[tractId] || [];
          
          console.log('Tract ID:', tractId);
          console.log('Metric Values for tract:', metricValues);
          
          return {
            ...feature,
            properties: {
              ...feature.properties,
              metrics: metricsData.headers.reduce((acc, header, index) => {
                acc[header] = metricValues[index];
                return acc;
              }, {})
            }
          };
        })
      };

      console.log('Updated Tract Data:', updatedTractData);
      setTractGeoJson(updatedTractData);

    } catch (error) {
      console.error('Error fetching tract data:', error);
    } finally {
      setIsLoadingTracts(false);
    }
  };

  // Update the tractStyle function
  const tractStyle = (feature) => {
    if (!primaryMetric) {
      // Default style when no primary metric is selected
      const isSelected = selectedTractId === feature.properties.GEOID;
      return {
        fillColor: isSelected ? '#ff4444' : '#FFA500',
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    // Get the metric value from the feature properties
    const countyId = feature.properties.COUNTYFP;
    const tractId = feature.properties.TRACTCE;
    const metricValues = metricsData.data[countyId]?.[tractId] || [];
    const metricIndex = metricsData.headers.indexOf(primaryMetric.item_mapping[0]);
    const metricValue = metricValues[metricIndex];
    
    const isSelected = selectedTractId === feature.properties.GEOID;
    
    // Handle missing or invalid data
    if (metricValue === undefined || metricValue === null || Number(metricValue) < 0) {
      return {
        fillColor: '#808080', // Gray for no data
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    }

    // Get all valid values for this metric to calculate the range
    const allValues = Object.values(metricsData.data[countyId] || {})
      .map(values => values[metricIndex])
      .filter(value => value !== null && value !== undefined && Number(value) >= 0)
      .map(Number);

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    const percentage = (Number(metricValue) - min) / (max - min);
    
    // Get the color gradient array
    const colors = mapColors.list_of_9[selectedColorGradient];
    
    // Calculate the index in the color array (0-8)
    const colorIndex = Math.min(Math.floor(percentage * 9), 8);
    const color = colors[colorIndex];

    return {
      fillColor: color,
      weight: isSelected ? 3 : 2,
      opacity: opacityColorGradient,
      color: isSelected ? lineColor[0] : lineColor[1],
      dashArray: '3',
      fillOpacity: isSelected ? opacitySelected : opacityUnselected
    };
  };

  // Update the onEachTract function to handle the new data structure
  const onEachTract = (feature, layer) => {
    layer.on({
      click: (e) => {
        L.DomEvent.stopPropagation(e);
        setSelectedTractId(feature.properties.GEOID);
        setSelectedFeature(feature);
        
        // Log the metrics data for debugging
        const countyId = feature.properties.COUNTYFP;
        const tractId = feature.properties.TRACTCE;
        const metricValues = metricsData.data[countyId]?.[tractId] || [];
        
        console.log('Selected Tract:', {
          countyId,
          tractId,
          metricValues,
          headers: metricsData.headers
        });
        
        layer.setStyle(tractStyle(feature));
      },
    });
  };

  // You might want to add a function to go back to counties view
  const handleBackToCounties = () => {
    setTractGeoJson(null);
    setSelectedTractId(null);
    setViewMode('counties');
    setGeoJsonData(countyGeoJson);  // Restore the county data
    setSelectedFeature(null);
    
    // Reset map view with animation
    if (mapRef.current) {
      mapRef.current.flyTo(initialCenter, initialZoom, {
        duration: 1.5,
        easeLinearity: 0.25
      });
    }
  };

  // Helper function to get all children for an item
  const getChildren = (itemId) => {
    return Object.values(MetricHierarchy).filter(item => item.parent === itemId);
  };

  // Add this new function to fetch metrics data
  const fetchMetricsData = async (metrics) => {
    if (!selectedState) return;
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/geometries/state_metrics/${selectedState}/all`,
        {
          Metric_ids: metrics.flatMap(metric => metric.item_mapping)
        }
      );

      // Fetch data from each URL returned in the response
      const metricPromises = Object.entries(response.data.metrics).map(async ([baseMetric, url]) => {
        const metricResponse = await axios.get(url);
        return metricResponse.data;
      });

      const metricsResults = await Promise.all(metricPromises);
      
      // Merge all the metric data
      metricsResults.forEach(metricData => {
        mergeMetricsData(metricData);
      });

      // If we're viewing tracts, update the tract data with new metrics
      if (viewMode === 'tracts' && tractGeoJson) {
        const updatedTractData = {
          ...tractGeoJson,
          features: tractGeoJson.features.map(feature => {
            const countyId = feature.properties.COUNTYFP;
            const tractId = feature.properties.TRACTCE;
            const metricValues = metricsData.data[countyId]?.[tractId] || [];
            
            return {
              ...feature,
              properties: {
                ...feature.properties,
                metrics: metricsData.headers.reduce((acc, header, index) => {
                  acc[header] = metricValues[index];
                  return acc;
                }, {})
              }
            };
          })
        };
        setTractGeoJson(updatedTractData);
      }
    } catch (error) {
      console.error('Error fetching metrics data:', error);
    }
  };

  // Modify the handleMetricChange function
  const handleMetricChange = async (newMetrics) => {
    let updatedMetrics;
    
    if (Array.isArray(newMetrics)) {
        updatedMetrics = newMetrics;
        setSelectedMetrics(newMetrics);
    } else {
        setSelectedMetrics(prevMetrics => {
            updatedMetrics = newMetrics(prevMetrics);
            return updatedMetrics;
        });
    }

    // Clear selections when metrics change
    setSelectedFeature(null);
    setSelectedTractId(null);
    setSelectedFeatureId(null);

    // Determine which metrics are newly added
    const previousMetricIds = new Set(selectedMetrics.flatMap(m => m.item_mapping));
    const newlyAddedMetrics = updatedMetrics.filter(metric => 
        !metric.item_mapping.every(id => previousMetricIds.has(id))
    );

    if (newlyAddedMetrics.length > 0) {
        await fetchMetricsData(newlyAddedMetrics);
    }

    setMetricsModified(true);
  };

  // Update the useEffect for initial metrics
  useEffect(() => {
    if (initialMetrics?.length > 0) {
      fetchMetricsData(initialMetrics);
    }
  }, [initialMetrics, selectedState]);

  // Add this function to handle reload
  const handleReload = async () => {
    try {
      // Reset to county view
      setTractGeoJson(null);
      setSelectedTractId(null);
      setViewMode('counties');
      setGeoJsonData(countyGeoJson);
      setSelectedFeature(null);
      setSelectedFeatureId(null);
      setMetricsModified(false);
      
      // Reset map view
      if (mapRef.current) {
        mapRef.current.setView(initialCenter, initialZoom);
      }
    } catch (error) {
      console.error('Error reloading map:', error);
    }
  };

  const handleSetPrimaryMetric = (metric) => {
    setPrimaryMetric(metric);
    if (viewMode === 'tracts' && tractGeoJson) {
      colorTractsByMetric(tractGeoJson, metric);
    }
  };

  const colorTractsByMetric = (geoJson, metric) => {
    if (!geoJson || !metric) return;

    // Get all values for this metric
    const values = geoJson.features
      .map(f => f.properties.metrics?.[metric.item_mapping[0]])
      .filter(v => v !== undefined && v !== null)
      .map(Number);

    const min = Math.min(...values);
    const max = Math.max(...values);

    // Update tract style function
    const tractStyle = (feature) => {
      const isSelected = selectedTractId === feature.properties.GEOID;
      const metricValue = feature.properties.metrics?.[metric.item_mapping[0]];
      
      if (metricValue === undefined || metricValue === null) {
        return {
          fillColor: '#gray',
          weight: isSelected ? 3 : 2,
          opacity: opacityColorGradient,
          color: isSelected ? lineColor[0] : lineColor[1],
          dashArray: '3',
          fillOpacity: isSelected ? opacitySelected : opacityUnselected
        };
      }

      // Calculate color on a scale from red to green
      const percentage = (metricValue - min) / (max - min);
      const hue = percentage * 120; // 0 is red, 120 is green
      const color = `hsl(${hue}, 75%, 50%)`;

      return {
        fillColor: color,
        weight: isSelected ? 3 : 2,
        opacity: opacityColorGradient,
        color: isSelected ? lineColor[0] : lineColor[1],
        dashArray: '3',
        fillOpacity: isSelected ? opacitySelected : opacityUnselected
      };
    };

    // Update the GeoJSON style
    setTractGeoJson({
      ...geoJson,
      features: geoJson.features.map(feature => ({
        ...feature,
        style: tractStyle(feature)
      }))
    });
  };

  const formatMetricValue = (value, metricId) => {
    if (Number(value) < 0) return '(X)';
    
    // Convert to number for formatting
    const numValue = Number(value);
    
    // Format based on metric type
    if (metricId.endsWith('PE') || metricId.endsWith('PM')) {
      // Format as percentage with 1 decimal place
      return `${numValue.toFixed(1)}%`;
    } else if (metricId.endsWith('E') || metricId.endsWith('M')) {
      // Format as number with comma separators
      return numValue.toLocaleString();
    }
    
    // Default formatting if no specific type matches
    return value;
  };

  // Add a click handler for the map
  const MapClickHandler = () => {
    const map = useMap();
    
    useEffect(() => {
      const handleMapClick = (e) => {
        // Check if click was on a tract (handled by onEachFeature)
        // If not, clear the selection
        if (viewMode === 'tracts') {
          setSelectedTractId(null);
          setSelectedFeature(null);
        }
      };

      map.on('click', handleMapClick);

      return () => {
        map.off('click', handleMapClick);
      };
    }, [viewMode]);

    return null;
  };

  // Add this helper function to group metrics
  const groupMetrics = (metrics) => {
    console.log('Grouping metrics:', metrics);
    const groups = {};
    
    // Get all base metric IDs from selectedMetrics (without E, M, PE, PM suffixes)
    const selectedBaseMetricIds = selectedMetrics.flatMap(m => 
      m.item_mapping.map(id => id.replace(/[EMP]+$/, ''))
    );
    console.log('Selected base metric IDs:', selectedBaseMetricIds);
    
    Object.entries(metrics).forEach(([metricId, value]) => {
      // Get the base name without suffixes
      const baseName = metricId.replace(/[EMP]+$/, '');
      
      // Only process metrics whose base name is in selectedMetrics
      if (!selectedBaseMetricIds.includes(baseName)) {
        return;
      }

      if (!groups[baseName]) {
        // Find the metric from selectedMetrics that matches this base ID
        const metric = selectedMetrics.find(m => 
          m.item_mapping.some(id => id.startsWith(baseName))
        );
        
        groups[baseName] = {
          label: metric?.label || baseName,
          values: {}
        };
      }
      
      if (metricId.endsWith('PE')) groups[baseName].values.PE = value;
      else if (metricId.endsWith('PM')) groups[baseName].values.PM = value;
      else if (metricId.endsWith('E') && !metricId.endsWith('PE')) groups[baseName].values.E = value;
      else if (metricId.endsWith('M') && !metricId.endsWith('PM')) groups[baseName].values.M = value;
    });
    
    console.log('Grouped metrics:', groups);
    return groups;
  };

  return (
    <div style={{
      position: "relative",
      width: "100%",
      height: "100vh",
      overflow: "hidden",
      margin: 0,
      padding: 0
    }}>
      <div style={{
        position: "absolute",
        top: "10px",
        left: isPanelVisible ? "410px" : "10px",
        right: "70px",
        backgroundColor: "white",
        padding: "8px 16px",
        zIndex: 999,
        border: "1px solid #ddd",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        gap: "24px",
        height: "48px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        transition: "left 0.3s ease-in-out"
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: '4px'
        }}>
          <button style={{
            padding: '4px 12px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            display: 'flex',
            alignItems: 'center',
            gap: '8px'
          }}>
            <span><strong>State:</strong> {' ' +selectedState?.toUpperCase() || 'State'}</span>
          </button>

          <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />

          <button style={{
            padding: '4px 12px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '14px',
            opacity: selectedCounty || (viewMode === 'counties' && selectedFeature) ? 1 : 0.5,
          }}
          onClick={() => handleBackToCounties()}
          >
            {viewMode === 'counties' && selectedFeature ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +selectedFeature.properties.NAMELSAD}</span>
              </>
            ) : viewMode === 'tracts' && selectedCounty ? (
              <>
                <strong>County:</strong> 
                <span>{' ' +selectedCounty.properties.NAMELSAD}</span>
              </>
            ) : (
              'County'
            )}
          </button>

          {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
            <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
          )}

          {(viewMode === 'tracts' || (viewMode === 'counties' && selectedFeature)) && (
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: viewMode === 'counties' && selectedFeature ? 'pointer' : 'default',
              fontSize: '14px',
              opacity: viewMode === 'tracts' ? 1 : 0.5,
            }}
            onClick={() => {
              if (viewMode === 'counties' && selectedFeature) {
                handleViewTracts(selectedFeature.properties.GEOID);
              }
            }}
            >
              {viewMode === 'tracts' && selectedFeature ? (
                <>
                  <strong>Tract:</strong> 
                  <span>{' ' +selectedFeature.properties.GEOIDFQ}</span>
                </>
              ) : (
                <>
                  <strong>Tract:</strong> 
                </>
              )}
            </button>
          )}

          {primaryMetric && (
            <KeyboardArrowRightIcon sx={{ color: '#666', fontSize: 40 }} />
          )}

          {primaryMetric && (
            <button style={{
              padding: '4px 12px',
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'default',
              fontSize: '14px',
            }}>
              <strong>Metric:</strong> <span>{' ' +primaryMetric.label}</span>
            </button>
          )}
        </div>
      </div>

      <div style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}>
        {maxBounds && (
          <MapContainer 
            key={`${selectedState}-${initialCenter[0]}-${initialCenter[1]}`}
            center={initialCenter}
            zoom={initialZoom} 
            maxBounds={maxBounds}
            maxBoundsViscosity={1.0}
            minZoom={minZoom}
            maxZoom={maxZoom}
            style={{
              width: "100%",
              height: "100%"
            }}
            ref={mapRef}
            zoomControl={false}
            attributionControl={false}
          >
            <ZoomControl position="topright" />
            <TileLayer
              attribution='&copy; <a href="https://www.stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url={`https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=8f074345-7c64-4b84-bedc-a74592e223c5`}
              minZoom={0}
              maxZoom={20}
            />
            {/* County boundaries */}
            {viewMode === 'counties' && geoJsonData && (
              <GeoJSON 
                key={selectedFeatureId}
                data={geoJsonData} 
                style={style}
                onEachFeature={onEachFeature}
              />
            )}
            
            {/* Tract boundaries */}
            {viewMode === 'tracts' && tractGeoJson && (
              <GeoJSON 
                key={`tracts-${selectedTractId}-${primaryMetric?.id}`}
                data={tractGeoJson}
                style={tractStyle}
                onEachFeature={onEachTract}
              />
            )}
            {selectedFeature && (
              <Popup
                position={getPopupPosition(selectedFeature)}
                maxHeight={400}
              >
                <div style={{ maxWidth: '300px' }}>
                  <h3 style={{ 
                    margin: '0 0 15px 0', 
                    fontSize: '1.2em',
                    borderBottom: '2px solid #2196f3',
                    paddingBottom: '8px',
                    color: '#1976d2'
                  }}>
                    {viewMode === 'counties' 
                      ? selectedFeature.properties.NAMELSAD 
                      : `Tract ${selectedFeature.properties.GEOIDFQ}`
                    }
                  </h3>
                  
                  {/* Metrics Display */}
                  {selectedFeature.properties.metrics && (
                    <Box sx={{ mt: 2 }}>
                      {Object.entries(groupMetrics(selectedFeature.properties.metrics)).map(([baseName, group]) => (
                        <MetricDisplay
                          key={baseName}
                          label={group.label}
                          values={group.values}
                        />
                      ))}
                    </Box>
                  )}

                  {/* Area Information */}
                  <div style={{ 
                    marginTop: '15px',
                    padding: '12px',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                    boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                  }}>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px',
                      borderBottom: '1px solid #e0e0e0',
                      paddingBottom: '4px'
                    }}>
                      <span style={{ color: '#666', fontWeight: '500' }}>Area:</span>
                      <span style={{ fontWeight: '600', color: '#333' }}>
                        {(selectedFeature.properties.ALAND / 2589988).toFixed(0)} sq mi
                      </span>
                    </div>
                    <div style={{ 
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <span style={{ color: '#666', fontWeight: '500' }}>Water Area:</span>
                      <span style={{ fontWeight: '600', color: '#333' }}>
                        {(selectedFeature.properties.AWATER / 2589988).toFixed(1)} sq mi
                      </span>
                    </div>
                  </div>

                  {/* View Tracts Button */}
                  {viewMode === 'counties' && (
                    <button 
                      onClick={() => handleViewTracts(selectedFeature.properties.GEOID)}
                      disabled={isLoadingTracts}
                      style={{
                        marginTop: '15px',
                        padding: '10px',
                        backgroundColor: isLoadingTracts ? '#bdbdbd' : '#2196f3',
                        color: 'white',
                        border: 'none',
                        borderRadius: '6px',
                        cursor: isLoadingTracts ? 'not-allowed' : 'pointer',
                        width: '100%',
                        fontWeight: '600',
                        transition: 'background-color 0.2s ease',
                        boxShadow: '0 2px 4px rgba(33,150,243,0.2)',
                        ':hover': {
                          backgroundColor: isLoadingTracts ? '#bdbdbd' : '#1976d2'
                        }
                      }}
                    >
                      {isLoadingTracts ? 'Loading Tracts...' : 'View Tracts'}
                    </button>
                  )}
                </div>
              </Popup>
            )}
            <MapClickHandler />
          </MapContainer>
        )}
        {error && <div style={{ position: 'absolute', zIndex: 2, backgroundColor: 'white', padding: '10px' }}>Error: {error}</div>}
      </div>

      <div style={{
        position: "absolute",
        left: isPanelVisible ? "0" : "-400px",
        top: "0",
        height: "100%",
        width: "400px",
        backgroundColor: "white",
        padding: "20px",
        boxSizing: "border-box",
        border: '1px solid #ccc',
        borderRadius: '0 5px 5px 0',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        transition: "left 0.3s ease-in-out",
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column'
      }}>
        <button
          onClick={togglePanelVisibility}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '5px 10px',
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 1
          }}
        >
          Hide Panel
        </button>

        <h2>Control Panel</h2>

        <FormControl 
          fullWidth 
          sx={{ 
            marginBottom: 2,
            flexShrink: 0,
            '& .MuiInputBase-root': {
              fontSize: '1.1rem',
              minHeight: '36px'
            },
            '& .MuiInputLabel-root': {
              fontSize: '1.1rem'
            },
            '& .MuiMenuItem-root': {
              fontSize: '1.1rem',
              padding: '12px 16px'
            }
          }}
        >
          <InputLabel id="hierarchy-type-label">Data Profile</InputLabel>
          <Select
            labelId="hierarchy-type-label"
            id="hierarchy-type-select"
            value={selectedHierarchyType}
            label="Data Profile"
            onChange={(e) => setSelectedHierarchyType(e.target.value)}
          >
            <MenuItem value="DP02">Social Characteristics</MenuItem>
            <MenuItem value="DP03">Economic Characteristics</MenuItem>
            <MenuItem value="DP04">Housing Characteristics</MenuItem>
            <MenuItem value="DP05">Demographic Characteristics</MenuItem>
          </Select>
        </FormControl>

        <div style={{
          flexGrow: 1,
          overflowY: 'auto',
          marginRight: '-20px',
          paddingRight: '20px',
          marginLeft: '-20px',
          paddingLeft: '20px',
        }}>
          <MetricSelection 
            metricsModified={metricsModified}
            handleReload={handleReload}
            showMetricsSelection={showMetricsSelection}
            setShowMetricsSelection={setShowMetricsSelection}
            MetricHierarchy={MetricHierarchy}
            expandedCategories={expandedCategories}
            setExpandedCategories={setExpandedCategories}
            handleMetricChange={handleMetricChange}
            getChildren={getChildren}
            selectedMetrics={selectedMetrics}
            primaryMetric={primaryMetric}
            handleSetPrimaryMetric={handleSetPrimaryMetric}
          />

          <ColorControls 
            selectedColorGradient={selectedColorGradient}
            setSelectedColorGradient={setSelectedColorGradient}
            opacityUnselected={opacityUnselected}
            setOpacityUnselected={setOpacityUnselected}
            opacitySelected={opacitySelected}
            setOpacitySelected={setOpacitySelected}
            opacityColorGradient={opacityColorGradient}
            setOpacityColorGradient={setOpacityColorGradient}
            isColorControlsCollapsed={isColorControlsCollapsed}
            setIsColorControlsCollapsed={setIsColorControlsCollapsed}
          />

          {/* Back to Counties button */}
          {viewMode === 'tracts' && (
            <button
              onClick={handleBackToCounties}
              style={{
                marginTop: '10px',
                marginBottom: '20px',
                padding: '8px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                width: '100%'
              }}
            >
              Back to Counties
            </button>
          )}
        </div>
      </div>

      {/* Show Panel button */}
      <button
        onClick={togglePanelVisibility}
        style={{
          position: 'absolute',
          top: '58px',
          left: isPanelVisible ? '-200px' : '10px',
          zIndex: 1001,
          padding: '5px 10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: "left 0.3s ease-in-out"
        }}
      >
        Show Panel
      </button>

      {/* Return Home Link */}
      <Link
        to="/account"
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          zIndex: 1001,
          padding: '8px 16px',
          backgroundColor: '#2196f3',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
          transition: 'background-color 0.3s ease',
          fontSize: '14px',
          fontWeight: 'bold',
          textDecoration: 'none',
          '&:hover': {
            backgroundColor: '#1976d2'
          }
        }}
      >
        Return Home
      </Link>

    </div>
  );
};

// Add prop types for type checking
StateMap.propTypes = {
  initialState: PropTypes.string,
  initialMetrics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      item_mapping: PropTypes.array
    })
  ),
  hierarchyType: PropTypes.string
};

export default StateMap;
